<template>
  <el-select
    v-model="localValue"
    @change="handlePoliceChange"
    autocomplete="new-password"
    :placeholder="$t('TEMPLATES_MAILS.TYPE')"
    :filterable="true"
    :multiple="false"
    :disabled="false"
    remote
    :loading="false"
  >
    <el-option
      v-for="(police, key) in fontFamilies"
      :key="key"
      :value="police"
      :label="`${police}`"
    />
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "polices-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    modelValue: {
      type: String,
      default: null,
      description: "police value",
    },
  },

  data() {
    return {
      localValue: this.modelValue,
      fontFamilies: [
        "Poppins, sans-serif",
        "Arial",
        "Times New Roman",
        "Courier New",
        "Georgia",
        "Verdana",
        "Trebuchet MS",
        "Comic Sans MS",
        "Roboto", // Google Font
        "Lato", // Google Font
        "Montserrat", // Google Font
      ],
    };
  },

  setup() {},

  created() {},

  methods: {
    handlePoliceChange() {
      this.$emit("update:modelValue", this.localValue); // Émet un événement pour synchroniser avec le parent
      this.$emit("policeChanged", this.localValue); // Émet un événement personnalisé
    },
    policeChanged(police) {
      this.$emit("policeChanged", police);
    },
  },

  watch: {
    modelValue(newVal) {
      this.localValue = newVal; // Met à jour la valeur locale si la prop change
    },
  },
};
</script>
