export default {
  type: "templates-mails",
  subject: null,
  title: null,
  from: null,
  recipients: null,
  cc: null,
  bcc: null,
  logo: null,
  background_color: null,
  sections: null,
  title_style: null,
  text_style: null,
  text_style: null,
  buttons_colors: null,
  content: "",
  is_default: false,
  relationshipNames: ["organization", "typesMail"],
  organization: {
    type: "organizations",
    id: null,
  },
  typesMail: {
    type: "types-mails",
    id: null,
  },
};
